<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Franchisee Payments/List</h4>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true"
                                label="name" track-by="name" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 pt-1">
                <div class="form-group">
                  <label>Start Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedFromDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 pt-1">
                <div class="form-group">
                  <label>End Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedToDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :max-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Franchisee</label>
                <VueMultiselect v-model="selectedFranchisee" class="" :options="franchiseeOptions"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"
                                :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-1 pt-1">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyCustomerFilter(null)">Filter
                </button>
              </div>
            </div>
            <div class="row pt-2">

            </div>
          </div>
        </div>

        <!--        franchisee payment start -->
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'10%'}">REFERENCE ID</th>
                    <th class="position-relative" :style="{width:'10%'}">FRANCHISEE</th>
                    <th class="position-relative" :style="{width:'20%'}">PAYMENT DATE</th>
                    <th class="position-relative" :style="{width:'30%'}">DATE RANGE</th>
                    <th class="position-relative" :style="{width:'10%'}">COMMISSION</th>
                    <th class="position-relative" :style="{width:'10%'}">TYPE</th>
                    <th class="position-relative" :style="{width:'10%'}">ACTION</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(franchiseePayment,index) in franchiseePayments" :key="index">
                    <td>
                      <span>{{ franchiseePayment.reference }}</span>
                    </td>
                    <td>
                      <span class="text-capitalize">{{ userFullName(franchiseePayment.franchisee) }}</span>
                    </td>
                    <td>
                      <span>{{ franchiseePayment.created_at }}</span>
                    </td>
                    <td>
                      <span>{{ franchiseePayment.from_date }}&nbsp;</span>--
                      <span>{{ franchiseePayment.to_date }}</span>
                    </td>
                    <td>
                      <span>{{ franchiseePayment.payment_for }}</span>
                    </td>
                    <td>
                      <span> {{ parseFloat(franchiseePayment.total / 100).toFixed(2) }}</span>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">

                        <div class="dropdown py-0 px-0 view-edit-delte-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">

                            <router-link
                                :to="{name:'appFranchiseePaymentView',params:{id:franchiseePayment.id}}"
                                class="dropdown-item cursor-pointer" :autocorrect="{height:'18px'}"
                                role="button">View Payments
                            </router-link>

                            <button @click="modelIdForDelete = franchiseePayment.id" data-toggle="modal"
                                    data-target="#deletePaymentModal" class="dropdown-item">Delete Payments
                            </button>

                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--        franchisee payment end -->


        <DeleteAlertModal modal-name="deletePaymentModal" model-name="franchiseePayment" :model-id="modelIdForDelete"
                          @confirmModelDeletion="onConfirmModelDeletion"/>
        <PaymentDetailsModal/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import PaymentDetailsModal from "@/views/backEnd/AccountAndFinance/Finance/AllPayments/includes/PaymentDetailsModal";
import {mapActions, mapGetters} from "vuex";

import VueMultiselect from "vue-multiselect";
import {DatePicker} from "v-calendar";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import DeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";

export default {
  name: "ListFranchiseePayments",
  components: {
    AppLayout,
    PaymentDetailsModal,
    DatePicker,
    VueMultiselect,
    DeleteAlertModal,
  },
  mixins: [Loader, ShowToastMessage],
  data() {
    return {
      modelIdForDelete: '',
      selectedFromDate: '',
      selectedToDate: '',
      selectedFranchisee: {
        value: '',
        name: 'None'
      },
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      technicalValue: {
        name: 'Any ',
        language: 'Any '
      },

      customerTypeSelectedValue: '',
      statusSelectedValue: '',

      currentGeekDetails: {
        id: "",
        name: "",
        address: "",
        type: "",
        phone: "",
        email: "",
        status: "",

      },
      getFranchiseePaymentsParams: {
        from_date: [],
        to_date: [],
        franchisee_id: '',
        with_relation: [
          'franchisee.user',

        ],
        paginate: 1,
        pagination: '',
        page: ''
      },
      getFranchiseesParams: {
        status: 1, // 1 for active technician
        with_relation: ['user', 'franchiseeTechnicians']
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination', 'default_state'],
      },
      franchiseeAppointmentPayment: [],
      franchiseeOrderPayment: [],
    }
  },
  computed: {
    ...mapGetters({
      franchiseePayments: 'appFranchiseePayments/franchiseePayments',
      paginateLinks: 'appFranchiseePayments/paginateLinks',
    }),
    pagination() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

    franchiseeOptions() {
      let activeFranchisees = this.$store.getters['appFranchisees/franchisees'].map((franchisee) => {
        let franchiseeId = franchisee.id;
        let fullName = `${franchisee?.user?.first_name ?? ''} ${franchisee?.user?.last_name ?? ''}`;
        return {
          value: franchiseeId,
          name: fullName
        };
      });
      return [{value: '', name: `No One`}, ...activeFranchisees];
    },

  },
  watch: {
    selectedPagination: function (pagination) {
      this.getFranchiseePaymentsParams.pagination = pagination.value;
    },
    selectedFromDate(value) {
      this.getFranchiseePaymentsParams.from_date[0] = value;
    },
    selectedToDate(value) {
      this.getFranchiseePaymentsParams.to_date[0] = value;
    },
    selectedFranchisee(currentValue) {
      this.getFranchiseePaymentsParams.franchisee_id = currentValue.value;
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getFranchiseePayments: 'appFranchiseePayments/getFranchiseePayments',
      getFranchisees: 'appFranchisees/getFranchisees',
      deleteFranchiseePayment: 'appFranchiseePayments/deleteFranchiseePayment',
      resetFranchisees: 'appFranchisees/resetFranchisees',
      resetFranchiseePayments: 'appFranchiseePayments/resetFranchiseePayments',
    }),

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSinglePaymentOnLIst(confirmModelDeletion.modelId);
    },

    async deleteSinglePaymentOnLIst(id) {
      this.deleteFranchiseePayment(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          this.showToastMessage({type: 'success', message: 'Franchisee Payment deleted successfully'});
          return;
        }
        this.showToastMessage(response);
      });
    },

    async filterFranchiseePayment() {
      this.franchiseeAppointmentPayment = [];
      this.franchiseeOrderPayment = [];
      this.franchiseePayments.forEach((singleFranchiseePayment) => {
        singleFranchiseePayment.details = JSON.parse(singleFranchiseePayment.details);
        if (singleFranchiseePayment.payment_for === 'Appointment') {
          singleFranchiseePayment['total'] = singleFranchiseePayment.details.reduce((accu, cur) => accu + cur.franchiseeCommissionFromAppointment, 0);
        } else {
          singleFranchiseePayment['total'] = singleFranchiseePayment.details.map((item) =>
              item.orderProductList.reduce((acc, cur) => acc + cur.productValue, 0)
          ).reduce((acc, cur) => acc + cur, 0);
        }
      });
    },

    async getFranchiseePaymentList(params) {
      this.loader(true);
      await this.getFranchiseePayments(params)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.loader(false);
              this.showToastMessage(response);
            } else {
              this.loader(false);
            }
          });
    },

    userFullName(customerObj) {
      return `${customerObj.user.first_name} ${customerObj.user.last_name}`;
    },
    async applyCustomerFilter(pageNumber) {
      this.getFranchiseePaymentsParams.page = pageNumber;
      await this.getFranchiseePaymentList(this.getFranchiseePaymentsParams);
      await this.filterFranchiseePayment();
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList(this.getSettingsParams);
    await this.getFranchisees(this.getFranchiseesParams);
    // await this.getFranchiseePaymentList(this.getFranchiseePaymentsParams);
    await this.loader(false);
  },
  async beforeMount() {
    await this.resetFranchisees();
  }

}
</script>

<style scoped>

@media only screen and (max-width: 1337px) and (min-width: 320px) {
  .table {
    white-space: nowrap !important;
  }

}
</style>
